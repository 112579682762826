import { themeGet } from '@styled-system/theme-get'
import bgPattern from 'common/assets/image/testimonials/testimonials-bg.svg'
import styled from 'styled-components'

const SectionWrapper = styled.section`
  padding-top: 130px;
  padding-bottom: 130px;
  overflow: hidden;
  background: #fff url(${bgPattern}) no-repeat center;
  @media only screen and (max-width: 990px) {
    padding-top: 80px;
    padding-bottom: 80px;
  }
  @media only screen and (max-width: 568px) {
    padding-top: 0px;
    padding-bottom: 60px;
    margin-top: 60px;
  }

  .container {
  }
`
export const TestimonialsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 30px;
  @media screen and (max-width: 990px) {
    grid-template-columns: 1fr 1fr;
  }
  @media screen and (max-width: 568px) {
    grid-template-columns: 1fr;
    gap: 20px;
  }
`
export const Testimonial = styled.div`
  width: 100%;
  background-color: ${themeGet('colors.white')};
  border: 1px solid #eeefef;
  padding: 30px 25px;

  @media screen and (max-width: 568px) {
    padding: 25px 20px;
  }

  .title {
    font-size: ${themeGet('fontSizes.7', '15')}px;
    letter-spacing: -0.5px;
    margin-bottom: 6px;
    color: ${themeGet('colors.heading')};

    @media screen and (max-width: 568px) {
      font-size: ${themeGet('fontSizes.6', '15')}px;
      margin-bottom: 4px;
    }
  }

  .credit {
    color: #1e88a4;
    font-size: ${themeGet('fontSizes.5')}px;
    font-weight: ${themeGet('fontWeights.6', '700')};
    letter-spacing: -0.5px;

    @media screen and (max-width: 568px) {
      font-size: ${themeGet('fontSizes.3')}px;
    }
  }

  .owner-container {
    display: flex;
    align-items: center;
    margin-top: 14px;
    gap: 15px;

    @media screen and (max-width: 568px) {
      margin-top: 16px;
      gap: 12px;
    }

    .image-container {
      position: relative;
      display: flex;
      width: auto;
      margin-left: 8px;
    }

    .image-content {
      width: 45px;
      height: 45px;
      margin-left: -8px;
      position: relative;
      overflow: hidden;
      border-radius: 50%;
      outline: 3px solid white;

      img {
        margin: 0px;
        display: inline;
      }
    }
    .image-0 {
      z-index: 10;
    }

    .owner-name {
      font-size: ${themeGet('fontSizes.5', '13')}px;
      font-weight: ${themeGet('fontWeights.5')};
      font-family: 'Poppins';
      color: ${themeGet('colors.heading')};
      letter-spacing: -0.5px;

      @media screen and (max-width: 568px) {
        font-size: ${themeGet('fontSizes.3', '13')}px;
      }
    }

    .occupation {
      font-family: 'Poppins';
      font-size: ${themeGet('fontSizes.3', '13')}px;
      color: #77797c;
      margin-top: -4px;
      font-weight: 500;

      @media screen and (max-width: 568px) {
        font-size: 13px;
        margin-top: 0px;
      }
    }
  }

  .shortTestimonial {
    font-size: ${themeGet('fontSizes.5')}px;
    font-weight: ${themeGet('fontWeights.5')};
    color: ${themeGet('colors.heading')};
    margin-top: 18px;
    margin-bottom: 14px;
    line-height: 24px;
    letter-spacing: -0.5px;
    font-family: 'Poppins';

    @media screen and (max-width: 568px) {
      font-size: ${themeGet('fontSizes.5')}px;
      margin-bottom: 10px;
      line-height: 21px;
    }
  }

  .detailedTestimonial {
    font-size: ${themeGet('fontSizes.5')}px;

    @media screen and (max-width: 568px) {
      font-size: ${themeGet('fontSizes.2')}px;
      line-height: 20px;
    }
  }

  .detailedTestimonial2 {
    font-size: ${themeGet('fontSizes.5')}px;
    margin-bottom: 20px;

    @media screen and (max-width: 568px) {
      font-size: ${themeGet('fontSizes.2')}px;
      line-height: 20px;
      margin-bottom: 15px;
    }
  }

  .image-container {
    width: 100%;
    img {
      object-fit: contain;
      width: 100%;
      margin: 18px 0;

      @media screen and (max-width: 568px) {
        margin: 15px 0;
      }
    }
  }

  .summaryText {
    font-size: ${themeGet('fontSizes.5')}px;
    line-height: 28px;
    font-weight: ${themeGet('fontWeights.6', '700')};

    @media screen and (max-width: 568px) {
      font-size: ${themeGet('fontSizes.2')}px;
      line-height: 22px;
    }
  }
`

export default SectionWrapper
